<template>
  <div class="container">
    welcome
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
}
/* eslint-disable */
</script>
<style scoped>

</style>
